<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html,
body {
  background-color: #0c0c0c;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
  line-height: 1.6;
  /* margin-top: 60px; */
}

a,
router-link {
  color: #3873cc;
  font-weight: bold;
  /* transition: ; */
}

a:hover {
  color: #0b5acf;
}

a:visited {
  color: #678fcc;
}
hr {
    border: 0;
    border-top: 2px dashed #CC0000;
    margin-top: -10px;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 0;
    max-width: 33vw;
    /* display: block; */
}

hr.title-break {
    border-top: 2px solid #CC0000;
}

.section {
    padding: 20px;
    background-color: #141414;
    border-left: 5px solid #CC0000;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.lesson-content {
    text-align: left;
    margin: 10px;
    padding: 10px;
}
ul, ol {
    margin-left: 20px;
    margin-bottom: 15px;
}
.lesson-content br {
    display: block;
    margin-bottom: -7px;
}
</style>

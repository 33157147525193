import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "@/views/HomeView.vue";
import NotFound from "@/views/NotFound.vue";
import LessonView from "@/views/LessonView.vue";
import AllLessons from "@/views/AllLessons.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: AllLessons,
    meta: { title: "Lessons | KillAllChickens" },
  },
  {
    path: "/lesson/:id",
    name: "Lesson",
    component: LessonView,
    meta: { title: "Lesson | KillAllChickens" }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: "Page Not Found | KillAllChickens" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
    <h1>404, Page not found!</h1>
    <p>We Cant Find It!</p>
    <a href="/">Go to all lessons</a>
</template>

<script>
export default {
    name: "NotFound",
}
</script>

<style scoped>
h1 {
    color: red;
}
a:hover {
    color: #0b5acf;
}
</style>
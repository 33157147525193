<template>
    <h1>All Lessons!</h1>
    <div class="lessons-container">
        <div class="lesson-item" v-for="(lesson, index) in lessons" :key="index">
            <div class="lesson-sdiv" @click="openLesson(lesson.id)">
                <div class="lesson-image" v-if="lesson.hasImg">
                    <img :src="lesson.image.src" />
                </div>
                <div class="lesson-text">
                    <div class="lesson-title">{{ lesson.title }}</div>
                    <div class="lesson-desc">{{ lesson.sDesc }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lessonData from '@/data/lessons.json';

export default {
    name: "AllLessons",
    data() {
        return {
            lessons: null
        };
    },
    methods: {
        openLesson(id) {
            this.$router.push({ name: "Lesson", params: { id: id } });
        }
    },
    created() {
        this.lessons = lessonData
    }
}
</script>

<style scoped>
.lessons-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lesson-item {
    /* border: 1px dashed #CC0000; */
    display: inherit;
    justify-content: center;
}

.lesson-sdiv {
    min-width: 30vw;
    max-width: 30vw;
    padding: 25px;
    border: 3px solid #CC0000;
    border-radius: 10px;
    text-align: left;
    display: inherit;
    align-items: center;
    transition: transform 0.2s linear, box-shadow 0.2s linear;
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    background-color: #131313;
}

.lesson-sdiv:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.5);
}

.lesson-sdiv .lesson-text {
    min-height: 100px;
    /* border: 2px dashed red; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    text-align: left;
}

.lesson-sdiv .lesson-title {
    font-size: 1.5rem;
    font-weight: bold;
    
}

.lesson-sdiv .lesson-desc {
    font-size: 1rem;
    padding-top: 10px;
}

.lesson-sdiv .lesson-image img {
    max-height: 100px;
    border: 1px dashed whitesmoke;
    border-radius: 5px;
}

.lesson-sdiv .lesson-image {
    padding-right: 25px;
}

@media (max-width: 900px) {
    .lesson-sdiv {
        min-width: 80vw; 
    }
}

@media (max-width: 600px) {
    .lesson-sdiv {
        min-width: 90vw; 
    }
}
</style>
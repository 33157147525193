<template>
    <div class="back-arrow-div">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="size-6 back-arrow" @click="goBack">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
    </div>
    <component :is="lessonComponent" />
</template>

<script>
import lessonsData from '@/data/lessons.json';
import { defineAsyncComponent } from 'vue';
import NotFound from './NotFound.vue';


export default {
    name: "LessonView",
    data() {
        return {
            lessonComponent: null
        };
    },
    created() {
        this.setLessonComponent();
    },
    methods: {
        setLessonComponent() {
            const lesson = lessonsData.find(lesson => lesson.id === this.$route.params.id);
            if (lesson) {
                this.lessonComponent = defineAsyncComponent(() =>
                    import(`@/lessons/${lesson.cName}`)
                );
                document.title = `${lesson.pTitle} | KillAllChickens`;
            } else {
                this.lessonComponent = NotFound;
            }
        },
        goBack() {
            this.$router.push({ name: "Home" })
        }
    }
};
</script>

<style scoped>
/* Nav buttons container */
.back-arrow {
    padding: 5px;
    background-color: rgba(192, 0, 0, 0.295);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-arrow:hover {
    background-color: rgba(192, 0, 0, 0.6);
}

/* Back arrow SVG */
.back-arrow {
    height: 24px;
    width: 24px;
    color: #fff;
    transition: transform 0.3s ease, color 0.3s ease;
}

.back-arrow:hover {
    transform: scale(1.1);
    color: #ffeb3b;
    
}
</style>